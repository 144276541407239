export const soilTypeOptions = [
  {
    name: 'Loam',
    value: 'loam',
  },
  {
    name: 'Silt loam',
    value: 'siltLoam',
  },
  {
    name: 'Silt',
    value: 'silt',
  },
  {
    name: 'Silty Clay Loam',
    value: 'siltClayLoam',
  },
  {
    name: 'Silty Clay',
    value: 'siltyClay',
  },
  {
    name: 'Clay',
    value: 'clay',
  },
];
